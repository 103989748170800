import React from 'react'
import {Layout} from 'widgets'
import {AirplaneSeparator, BackgroundHeart, ContactOkMail} from 'images'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from 'reducers'
import {navigate} from 'gatsby'

export default () => {
    const dispatch = useDispatch()
    const quoteRequest = useSelector((state: RootState) => state.quoteRequest)
    React.useEffect(() => {
        if(!quoteRequest)
            navigate('/')
        return () => {
            dispatch({
                type: 'REMOVE_QUOTE_REQUEST'
            })
            navigate('/')
        }
    }, [quoteRequest, dispatch])
    return(
        <Layout>
            <div
                className="container-fluid"
                style={{
                    height: '85vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}    
            >
                <div className="row mt-3">
                    <div className="col-md-12 col-sm-12 text-center">
                        <div className="row">
                            <div className="col-12 col-md-12 mx-md-auto text-center">
                                <img src={ContactOkMail} className="w-100" alt="Gracias" style={{
                                    padding: '0 45%'
                                }} />
                                <h5 className="text-purple mt-3">Gracias por tu confianza, has elegido la mejor opción</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 mx-md-auto">
                                <img src={AirplaneSeparator} width="320" alt="Gracias" />
                            </div>
                        </div>
                            
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-5 px-md-4 mx-md-auto">
                                <p className="h6 text-center text-purple">
                                    ¡Felicidades &nbsp;
                                    <strong>{quoteRequest?.name + ' '+ quoteRequest?.lastname }</strong>!
                                    a tu correo <strong>{quoteRequest?.email}</strong> &nbsp;
                                    llegará la información de la compra que acabas de realizar.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 pl-0">
                                <img
                                    src={BackgroundHeart}
                                    alt="Gracias"
                                    className="m-0"
                                    style={{
                                        padding: '0 17%',
                                        marginLeft: '7% !important'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}